@import "../../../../../styles/constants";

#order-list-container {
  #count-filter-status {
    font-size: 24px;
    font-weight: bold;
  }
  .form-range-picker-field {
    width: 250px;
  }
  .ant-dropdown-button .ant-upload:hover {
    color: #40a9ff;
  }
  .order-status-container {
    .ant-card-body {
      padding: 0 16px;

      .ant-tabs-nav {
        margin-bottom: 0;
        &::before {
          display: none;
        }
      }
    }
    .ant-tabs-tab {
      padding: 16px 0;
    }
  }
  .ant-pagination {
    margin-right: 16px;
  }

}
.custom-render-cell {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
