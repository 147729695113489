#CSVExportButton__container {
	position: relative;
	#CSVExportButton__button {
		padding: 4px 0 4px 15px;
		#CSVExportButton__text {
			padding-right: 3px;
		}
		#CSVExportButton__divider {
			position: absolute;
			border-left: 1px solid #fff;
			top: 0;
			height: 100%;
		}
		#CSVExportButton__down-icon {
			font-size: 10px;
			vertical-align: baseline;
			padding: 0 10px 0 3px;
		}
	}
}
